<template>
  <div id="top">
    <!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
      <div class="hero-slider">
        <!-- <div class="hero-inner overlay" style="background-image: url(../assets/images/banner01.png);"> -->
        <div
          class="breadcrumbs overlay"
          :style="{
            backgroundImage:
              'url(' + require('../../assets/images/callforpaperBg.jpg') + ')',
          }"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">最新动态</h1>
                  <p>欢迎关注储能和智能电站关键技术论坛最新动态</p>
                </div>
                <ul class="breadcrumb-nav">
                  <li><a href="index.html">首页</a></li>
                  <li>最新动态</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section blog-single">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
            <div class="single-inner">
              <div class="post-thumbnils">
                <img src="static/picture/blog-single.jpg" alt="#" />
              </div>
              <div class="post-details">
                <div class="detail-inner">
                  <ul class="custom-flex post-meta">
                    <li>
                      <a href="javascript:void(0)">
                        <i class="lni lni-calendar"></i>
                        20th March 2023
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i class="lni lni-comments"></i>
                        35 Comments
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i class="lni lni-eye"></i>
                        55 View
                      </a>
                    </li>
                  </ul>
                  <h2 class="post-title">
                    <a href="javascript:void(0)"
                      >How to Improve Your Communication <br />Skill with in 7
                      Days.</a
                    >
                  </h2>
                  <p>
                    We denounce with righteous indige nation and dislike men who
                    are so beguiled and demo realized by the charms of pleasure
                    of the moment, so blinded by desire, that they cannot
                    foresee the pain and trouble that are bound to ensue; and
                    equal blame belongs to those who fail in their duty through
                    weakness of will, which is the same as saying through
                    shrinking from toil and pain. These cases are perfectly
                    simple and easy to distinguish. In a free hour, when our
                    power of choice is untrammelled and when nothing prevents
                    our being able to do what we like best, every pleasure is to
                    be welcomed and every pain avoided.
                  </p>
                  <h3>What should you have to know.</h3>
                  <ul class="list">
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>Lorem Ipsum is
                      simply dummy text of the printing and typesetting
                      industry.
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>Lorem Ipsum is
                      simply dummy text of the printing and .
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>Lorem Ipsum is
                      simply dummy text of the printing and typesetting .
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>Lorem Ipsum is
                      simply dummy text of the.
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>Ipsum is
                      simply dummy text of the printing industry.
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia.
                  </p>

                  <blockquote>
                    <div class="icon">
                      <i class="lni lni-quotation"></i>
                    </div>
                    <h4>
                      "Don't demand that things happen as you wish, but wish
                      that they happen as they do happen, and you will go on
                      well."
                    </h4>
                    <span>Epictetus, The Enchiridion</span>
                  </blockquote>
                  <h3>Setting the mood with incense</h3>
                  <p>
                    Remove aversion, then, from all things that are not in our
                    control, and transfer it to things contrary to the nature of
                    what is in our control. But, for the present, totally
                    suppress desire: for, if you desire any of the things which
                    are not in your own control, you must necessarily be
                    disappointed; and of those which are, and which it would be
                    laudable to desire, nothing is yet in your possession. Use
                    only the appropriate actions of pursuit and avoidance; and
                    even these lightly, and with gentleness and reservation.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit.
                  </p>
                  <p>
                    Voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum. Sed
                    ut perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium.
                  </p>

                  <div class="post-tags-media">
                    <div class="post-tags popular-tag-widget mb-xl-40">
                      <h5 class="tag-title">Related Tags</h5>
                      <div class="tags">
                        <a href="javascript:void(0)">Education</a>
                        <a href="javascript:void(0)">Courses</a>
                        <a href="javascript:void(0)">Html</a>
                      </div>
                    </div>
                    <div class="post-social-media">
                      <h5 class="share-title">Social Share</h5>
                      <ul class="custom-flex">
                        <li>
                          <a href="javascript:void(0)" class="facebook">
                            <i class="lni lni-facebook-original"></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" class="twitter">
                            <i class="lni lni-twitter-original"></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" class="google">
                            <i class="lni lni-google"></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" class="pinterest">
                            <i class="lni lni-pinterest"></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" class="vimeo">
                            <i class="lni lni-vimeo"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="post-comments">
                  <h3 class="comment-title">Post comments</h3>
                  <ul class="comments-list">
                    <li>
                      <div class="comment-img">
                        <img src="static/picture/comment1.png" alt="img" />
                      </div>
                      <div class="comment-desc">
                        <div class="desc-top">
                          <h6>Rosalina Kelian</h6>
                          <span class="date">19th May 2023</span>
                          <a href="javascript:void(0)" class="reply-link"
                            ><i class="lni lni-reply"></i>Reply</a
                          >
                        </div>
                        <p>
                          Donec aliquam ex ut odio dictum, ut consequat leo
                          interdum. Aenean nunc ipsum, blandit eu enim sed,
                          facilisis convallis orci. Etiam commodo lectus quis
                          vulputate tincidunt. Mauris tristique velit eu magna
                          maximus condimentum.
                        </p>
                      </div>
                    </li>
                    <li class="children">
                      <div class="comment-img">
                        <img src="static/picture/comment2.png" alt="img" />
                      </div>
                      <div class="comment-desc">
                        <div class="desc-top">
                          <h6>
                            Arista Williamson
                            <span class="saved"
                              ><i class="lni lni-bookmark"></i
                            ></span>
                          </h6>
                          <span class="date">15th May 2023</span>
                          <a href="javascript:void(0)" class="reply-link"
                            ><i class="lni lni-reply"></i>Reply</a
                          >
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="comment-img">
                        <img src="static/picture/comment3.png" alt="img" />
                      </div>
                      <div class="comment-desc">
                        <div class="desc-top">
                          <h6>Arista Williamson</h6>
                          <span class="date">12th May 2023</span>
                          <a href="javascript:void(0)" class="reply-link"
                            ><i class="lni lni-reply"></i>Reply</a
                          >
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="comment-form">
                  <h3 class="comment-reply-title">Leave a comment</h3>
                  <form action="#" method="POST">
                    <div class="row">
                      <div class="col-lg-4 col-md-12 col-12">
                        <div class="form-box form-group">
                          <input
                            type="text"
                            name="#"
                            class="form-control form-control-custom"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-box form-group">
                          <input
                            type="email"
                            name="#"
                            class="form-control form-control-custom"
                            placeholder="Your Email"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-box form-group">
                          <input
                            type="email"
                            name="#"
                            class="form-control form-control-custom"
                            placeholder="Your Subject"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-box form-group">
                          <textarea
                            name="#"
                            rows="6"
                            class="form-control form-control-custom"
                            placeholder="Your Comments"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="button">
                          <button type="submit" class="btn mouse-dir white-bg">
                            Post Comment <span class="dir-part"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <a
      href="javascript:void(0)"
      class="scroll-top btn-hover"
      @click="scrollToAnchor('top')"
    >
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>
</template>
  
<script>
import Banner from "@/components/header/banner.vue";
import MyNav from "@/components/nav/nav.vue";
import {newsDetail} from "@/api/api"

export default {
  name: "IndexPage",
  components: {
    Banner,
    MyNav,
  },
  // beforeRouteUpdate(to,from,next){
  //   console.log(111111)
  //    this.detail = false;
  //   if(to.params.type != this.type){
  //     this.type = to.params.type;
  //   }
  //   next()
  // },

  data() {
    return {
      details: {}
    };
  },

  created() {
    if (this.$route.params) {
      let index = parseInt(this.$route.params.index);
      this.getDetailById(index)
    }
  },
  methods: {
    getDetailById(id){
      let query ={
        id: id
      }
      newsDetail(query).then(response => {
          if(response.code == 0){
             this.details = response.data
          }
          that.loading = false;
      });
    },
    toNews(type) {
      this.$router.push({
        path: "/new/" + type,
      });
    },

    upPage() {
      if (this.index == 1) {
        return;
      } else {
        this.index = this.index - 1;
        this.lastTitle = this.title[this.index - 1];
        this.nextTitle = this.title[this.index + 1];
      }
    },
    downPage() {
      if (this.index == 5) {
        return;
      } else {
        this.index = this.index + 1;
        this.lastTitle = this.title[this.index - 1];
        this.nextTitle = this.title[this.index + 1];
      }
    },
  },
};
</script>
  
<style>
</style>