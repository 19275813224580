import  Vue  from 'vue'
import  VueRouter   from "vue-router";
import Index from "../pages/indexpage.vue"
import Product from "../pages/productpage.vue"
import News from "../pages/newspage.vue"
import AboutUs from "../pages/aboutpage.vue"
import Contentus from "../pages/contentus.vue"
import ProductDetail from "../components/main/productDetail.vue"
import Data from "../pages/datapage.vue"
import DataDetail from "../components/main/dataDetail.vue"
import Case from "../pages/casepage.vue"
import NewsDetail from "../components/main/newsDetail.vue"
import CaseDetail from "../components/main/caseDetail.vue"
import Speaker from "../pages/speakerpage.vue"
import Callforpaper from "../pages/callforpaperpage.vue"

Vue.use(VueRouter)
const routes=[
    {
        path:"/index",
        component:Index
    },
    {
        path:"/",
        redirect:"/index"
    },
    {
        path:"/product/:type",
        component:Product
    },
    {
        path:"/newsHisNews",
        component:News
    },
    {
        path:"/about",
        component:AboutUs
    },
    {
        path:"/contentus",
        component:Contentus
    },
    {
        path:"/productDetail/:type",
        component:ProductDetail
    },
    {
        path:"/data",
        component:Data
    },
    {
        path:"/newsHisNews/:index",
        component:DataDetail
    },
    {
        path:"/case/:type",
        component:Case
    },
    {
        path:"/newsDetail/:index",
        component:NewsDetail
    },

    {
        path:"/caseDetail/:type",
        component:CaseDetail
    },
    {
        path:"/speaker",
        component:Speaker
    },
    {
        path:"/call",
        component:Callforpaper
    },
    
]
const router = new VueRouter({
    routes,
    mode: 'history'

})
export default router
